nav {
    position: fixed;
    top:10px;
    right:10px
}
.navButton {
    border:none;
    background-color: rgba(255, 255, 255, 0.5);
    padding:.5em;
    transform: rotate(90deg);
    font-size: 1em;
    color:black;
}
.navOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.navOverlay ul {
    list-style-type: none;
    padding: 0;
}
.navOverlay ul li {
    padding: 1em;
}
.navOverlay .closeNavButton {
    position: fixed;
    top:10px;
    right:10px;
    padding:.5em;
    font-size: 1em;
    border:none;
    background-color: inherit;
    color:black;
}
nav ul li {
    font-size: 1.5em;
    font-weight: bold;
}