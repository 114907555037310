* {
  box-sizing: content-box;
}

body {
  /* background-color: #e2e2e2; */
  font-family: "Encode Sans Semi Expanded", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Titan One", sans-serif;
    font-weight: 400;
    font-style: normal;
}

img {
  width: 50%;
}

img.gameThumbnail {
  margin: 10% auto;
}
.dropShadow {
box-shadow: 3px 3px 5px #b8b8b8;
}

main {
  display: grid;
  /* in the next line we define auto number of columns to fit as many of them as possible (auto-fit) with minimum width of the column to be 250px and maximum 1 fraction minmax(250px, 1fr). If you will resize the browser window you will see that Grid is changing the number of columns as per instructions above*/
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1px;
  align-items: center;
  justify-items: center;
  min-height: 100vh;
  outline: 1px solid;
}


video {
  height: 500px;
  border-radius: 30px;
}

section>div {
  margin: 1em;
}

footer {
  outline:none !important;
  padding: 2em 0 4em 0;
}

.grid {
  align-items: inherit;
  justify-items: inherit;
  align-items: center;
  justify-items: center;
}

.text {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App {
  text-align: center;
}

.outlinedContainer {
  width: 100%;
  height: 100%;
  outline: 1px solid;
}

.flexColumnCentered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.minHeight {
  min-height: inherit;
}

.storeIcons {
  width: 230px;
  margin: 1em auto;
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.storeIcons>div {
  justify-content: start;
  display: inherit;
  flex-direction: column;
  align-items: flex-start;
}

.storeIcon {
  width: 25%;
  border-radius: 20%;
}

.getOnAppStore {
  /*   height: 40px; */
  width: 120px;
}

.icon {
  width: 2rem;
  margin-right: .5em;
}

.heroImage {
  width: 100%;
  height: 100%;
  grid-column: 1/3;
}

.darkBackground {
  background-color: #1a1a1a;
  outline: 1px solid #e1dfdf;
  color: white;
  /* height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; */
}
.halveItBackground {
  background-color: #FFEAF9;
  outline: 1px solid #e1dfdf;
  color: black;
}

.meatysBackground {
  background-color: #D90C18;
  outline: 1px solid #e1dfdf;
  color: white;
}

.pickyEaterBackground {
  background-color: #5ddd47;
  outline: 1px solid #e1dfdf;
  color: black;
}
.guessAwordBackground {
  background-color: #a8ffeb;
  outline: 1px solid #e1dfdf;
  color: black;
}

a:link,
a:visited,
a:hover {
  color: black;
  text-decoration: none;
}

a:active {
  color: magenta;
}


@media all and (max-width: 800px) {
  .heroImage {
    grid-column: 1;
  }
}

@media all and (max-width: 500px) {
  .grid {
    grid-template-columns: 1fr !important;
  }

  main {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}